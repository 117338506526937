input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.bread_crumb {
  word-break: keep-all;
}

/* .recharts-legend-wrapper { */
/* left: 43px !important;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'segoeUI';
  src: url(../font/segoeUI.woff);
}

@font-face {
  font-family: 'segoeUIBold';
  src: url(../font/SegoeUIBold.woff);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

*:not(input):not(textarea) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  font-family: 'segoeUI';
  background: white;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
*,
input,
select,
textarea,
option,
button {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}

video {
  /* width: 100%; */
  height: 140px;
}

.noselect {
}

:root {
  --dark-blue: #091c4b;
  --light-blue: #225af6;
  --bg-blue: #f5f6fa;
  --border: #dce2f0;
  --placeholder: #091c4b5b;
  --white: #fff;
  --popup-grey: #f6f6f6;
  --red: #e31a1a;
  --dark-border: #979797;
}
input[type='search']:focus {
  outline: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select:focus {
  outline: none;
}

a {
  text-decoration: none !important;
}

.layout-container {
  display: flex;
  flex-direction: row;
  height: 97vh;
}
.pages-container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1201;
}
.main-web {
  width: 100%;
  height: 100%;
}
.pages {
  overflow: auto;
  padding: 30px;
  background: var(--bg-blue);
}

/* .recharts-default-legend{
  margin-right: 40px !important;
} */

.recharts-legend-wrapper {
  position: relative !important;
  /* margin-bottom: 10px !important; */
  /* margin-right: 61px!important; */
  margin-left: 40px !important;
  margin-top: -15px;
  width: 80% !important;
}

.recharts-legend-item {
  display: flex !important;
  flex-direction: row !important;
  align-items: baseline !important;
  margin-right: 0px !important;
}

.recharts-legend-item svg {
  width: 14px !important;
  min-width: 14px !important;
}
@media (max-height: 900px) {
  .recharts-legend-wrapper {
    margin-top: -5px !important;
  }
}
@media (max-height: 750px) {
  .recharts-legend-wrapper {
    margin-top: -10px !important;
  }
}

.form-element {
  &:not(.form-element-inline) {
    @apply flex flex-col mb-4;
    .form-label {
      @apply mb-2;
    }
    .form-hint,
    .form-error,
    .form-success {
      @apply mt-2;
    }
  }
  &.form-element-inline {
    @apply flex flex-row items-center justify-start;
    .form-label {
      @apply whitespace-nowrap;
    }
    > * {
      @apply mr-2;
    }
  }
  .form-input,
  .form-textarea {
    @apply block w-full text-xs;
    &.form-input-valid {
      @apply border border-green-500;
    }
    &.form-input-invalid {
      @apply border border-red-500;
    }
    /* [data-background="dark"] & {
      @apply text-white bg-gray-800 border-gray-700;
      &::placeholder {
        color: theme("colors.white");
      }
    } */
  }
  .form-select {
    @apply block w-full text-sm;
    &.form-select-valid {
      @apply border border-green-500;
    }
    &.form-select-invalid {
      @apply border border-red-500;
    }
    /* [data-background="dark"] & {
      @apply text-white bg-gray-800 border-gray-700;
    } */
  }
  .form-radio {
    &.form-radio-valid {
      @apply text-green-500;
      + span {
        @apply text-green-500;
      }
    }
    &.form-radio-invalid {
      @apply text-red-500;
      + span {
        @apply text-red-500;
      }
    }
  }
  .form-hint {
    @apply text-xs text-gray-500 whitespace-nowrap;
  }
  .form-error {
    @apply text-xs text-red-500 whitespace-nowrap;
  }
  .form-success {
    @apply text-xs text-green-500 whitespace-nowrap;
  }
}
.form-icon {
  @apply absolute bottom-0 w-4 h-4 stroke-current;
  margin-bottom: 10px;
  &.form-success {
    @apply text-red-500;
  }
  &.form-error {
    @apply text-green-500;
  }
  @apply right-0 mr-2;
}

a,
input[type='submit'],
button {
  @apply outline-none cursor-pointer;
}
a:hover,
a:active,
a:focus,
input[type='submit']:hover,
input[type='submit']:active,
input[type='submit']:focus,
button:hover,
button:active,
button:focus {
  @apply outline-none;
}

.btn {
  @apply p-2 font-bold uppercase;
  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  &.btn-sm {
    @apply px-2 py-1 text-xs;
  }
  &.btn-default {
    @apply px-4 py-2 text-xs;
  }
  &.btn-lg {
    @apply px-6 py-3 text-base;
  }
  &.btn-rounded {
    @apply rounded-lg;
  }
  &.btn-outlined {
    @apply border;
  }
  &.btn-raised {
    @apply shadow;
  }
  &.btn-icon {
    @apply flex flex-row items-center justify-center;
  }
  &.btn-block {
    @apply w-full;
  }
  &.btn-circle {
    @apply inline-flex items-center justify-center w-8 h-8 text-xs rounded-full;
    @apply p-0;
    &.btn-circle-sm {
      @apply inline-flex items-center justify-center w-6 h-6 text-xs;
    }
    &.btn-circle-lg {
      @apply inline-flex items-center justify-center w-12 h-12 text-base;
    }
  }
}

.btn-group {
  > .btn {
    &:first-child {
      @apply border-r-0 rounded-l;
    }
    &:last-child {
      @apply border-l-0 rounded-r;
    }
  }
}
